// intro
$(function() {
    let $headerIntroImage = $('.intro-image');
    let headerIntroImageWidth = $headerIntroImage.width();
    let headerIntroImageHeight = $headerIntroImage.height();
    let $headerIntroImageList = $('<ul class="intro-image__list"></ul>').appendTo($headerIntroImage);
    let $headerIntroImageListItem = [];
    let $headerIntroImageStyle = $('<style id="style-intro-background-image"></style>').appendTo($headerIntroImage);

    let headerIntroImagetUrl = [
        'img/intro/intro-bg-01.jpg',
        'img/intro/intro-bg-02.jpg',
        'img/intro/intro-bg-03.jpg',
        'img/intro/intro-bg-04.jpg'
    ];

    //let headerIntroImageRandom = headerIntroImage[Math.floor(Math.random() * headerIntroImagetUrl.length)];

    let headerIntroImageLeftIndex = 0;
    let headerIntroImageInited = false;

    let moveLeft = false;
    let moveRight = true;

    let $headerIntroImageSlidersList = $('<ul class="intro-image-sliders__list"></ul>').insertAfter($headerIntroImage);
    let $headerIntroImageSlidersListItem = $('<li class="intro-image-sliders__list-item intro-image-sliders__list-item--left"><i class="fa fa-angle-left"></i></li><li class="intro-image-sliders__list-item intro-image-sliders__list-item--right"><i class="fa fa-angle-right"></i></li>').appendTo($headerIntroImageSlidersList);
    let $headerIntroImageSlidersListItemLeft = $headerIntroImageSlidersList.find('.intro-image-sliders__list-item--left');
    let $headerIntroImageSlidersListItemRight = $headerIntroImageSlidersList.find('.intro-image-sliders__list-item--right');

    let $headerIntroImageDotsList = $('<ul class="intro-image-dots__list"></ul>').insertAfter($headerIntroImage);
    let $headerIntroImageDotsListItem = [];
    let headerIntroImageDotsInited = false;

    let autoMove = 5000; // 5s
    let autoMoveInterval;

    let actualViewportWidth = window.innerWidth;
    let desktopViewportWidth = 960;

    let actualViewport = ActualViewport();
    let lastViewport = actualViewport;

    let offset = $(window).scrollTop();

    let opacity;
    let opacityDefault = 1; // 0.5
    let opacityPercentageDefault = opacityDefault * 100;
    let opacityMultiplier = 1.5;

    //let blur;

    let scrollingPage = false;
    let scrollingPageTimeout = 1000; // 1s

    // intro items
    for (let i = 0; i < headerIntroImagetUrl.length; i++) {
        $headerIntroImageListItem.push($('<li class="intro-image__list-item intro-image__list-item--' + i + '"></li>').appendTo($headerIntroImageList));
        $headerIntroImageStyle.append(
            '.intro-image__list-item--' + i + ' {' +
                'background-image: url("' + headerIntroImagetUrl[i] + '");' +
            '}'
        );
    }

    StartCarousel();

    // control carousel
    function Control() {
        $headerIntroImageSlidersListItemLeft
            .off('click')
            .on('click', function() {
                AutoMoveReset();
                MoveLeft();
                AutoMove();
                return false;
            });
        $headerIntroImageSlidersListItemRight
            .off('click')
            .on('click', function() {
                AutoMoveReset();
                MoveRight();
                AutoMove();
                return false;
            });
    }

    // reorder items
    function Reorder() {
        for (let i = 0; i < $headerIntroImageListItem.length; i++) {
            let $item = jQuery($headerIntroImageListItem[i]);
            let itemWidth = headerIntroImageWidth;
            let itemPosition = (-headerIntroImageLeftIndex) * itemWidth;

            if (headerIntroImageInited) {
                $item.stop().animate({
                    left: itemPosition
                }, 'slow', 'easeInOutExpo');
            } else {
                $item.css('left', itemPosition);
            }
        }

        headerIntroImageInited = true;

        // active item class
        jQuery($headerIntroImageListItem).each(function() {
            $(this).removeClass('intro-image__list-item--selected');
        });
        jQuery($headerIntroImageListItem[headerIntroImageLeftIndex]).addClass('intro-image__list-item--selected');
    }

    // moveLeft
    function MoveLeft() {
        if (headerIntroImageLeftIndex > 0) {
            headerIntroImageLeftIndex--;
            Reorder();
            Sliders();
            Dots();

            if (headerIntroImageLeftIndex == 0) {
                moveLeft = false;
                moveRight = true;
            }
        }
        return false;
    }

    // moveRight
    function MoveRight() {
        if (headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1) {
            headerIntroImageLeftIndex++;
            Reorder();
            Sliders();
            Dots();

           if (headerIntroImageLeftIndex == $headerIntroImageListItem.length - 1) {
               moveRight = false;
               moveLeft = true;
           }
        }
        return false;
    }

    // sliders
    function Sliders() {
        if (headerIntroImageLeftIndex > 0)
            $headerIntroImageSlidersListItemLeft.attr('style', 'opacity: 1; pointer-events: auto;');
        else
            $headerIntroImageSlidersListItemLeft.attr('style', 'opacity: 0.25; pointer-events: none;');

        if (headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1)
            $headerIntroImageSlidersListItemRight.attr('style', 'opacity: 1; pointer-events: auto;');
        else
            $headerIntroImageSlidersListItemRight.attr('style', 'opacity: 0.25; pointer-events: none;');
        return false;
    }

    // dots
    function Dots() {
        // dots init
        if (!headerIntroImageDotsInited && $headerIntroImageDotsList.length != 0) {
            //$headerIntroImageDotsList.html(''); // reset

            for (let i = 0; i < $headerIntroImageListItem.length; i++) {
                $headerIntroImageDotsListItem.push($('<li class="intro-image-dots__list-item intro-image-dots__list-item--' + i + '"></li>').appendTo($headerIntroImageDotsList));
            }

            headerIntroImageDotsInited = true;
        }

        // active item class
        jQuery($headerIntroImageDotsListItem).each(function() {
            $(this).removeClass('intro-image-dots__list-item--selected');
        });
        jQuery($headerIntroImageDotsListItem[headerIntroImageLeftIndex]).addClass('intro-image-dots__list-item--selected');
    }

    // autoMove
    function AutoMove() {
        AutoMoveReset();

        if (autoMove != 0) {
            autoMoveInterval = window.setInterval(function() {
                if (offset <= headerIntroImageHeight && !scrollingPage) {
                    if (moveLeft && headerIntroImageLeftIndex > 0) {
                        MoveLeft();
                    } else if (moveRight && headerIntroImageLeftIndex < $headerIntroImageListItem.length - 1) {
                        MoveRight();
                    }
                    return false;
                }
                return false;
            }, autoMove);
        }
        return false;
    }

    // autoMove reset
    function AutoMoveReset() {
        window.clearInterval(autoMoveInterval);
    }

    // mobile viewport
    function ActualViewport() {
        actualViewportWidth = window.innerWidth;

        // mobile viewport
        if (actualViewportWidth < desktopViewportWidth) {
            mobileViewportFlag = true;
            desktopViewportFlag = false;
            return "mobile";
        }
        // desktop viewport
        else if (actualViewportWidth > desktopViewportWidth) {
            return "desktop";
        }
    }

    // start carousel
    function StartCarousel() {
        Control();
        Reorder();
        Sliders();
        Dots();
        AutoMove();
    }

    // reset
    function ResetCarousel() {
        headerIntroImageInited = false;
        //headerIntroImageDotsInited = false;

        headerIntroImageLeftIndex = 0;

        moveLeft = false;
        moveRight = true;

        actualViewport = ActualViewport();
        lastViewport = actualViewport;

        StartCarousel();
    }

    $(window).resize(function() {
        headerIntroImageWidth = $headerIntroImage.width();
        headerIntroImageHeight = $headerIntroImage.height();
        actualViewport = ActualViewport();
        Reorder();

        if (actualViewport != lastViewport) {
            ResetCarousel();
        }
    });

    $(window).scroll(function() {
        offset = $(window).scrollTop();

        if (offset >= headerIntroImageHeight) {
            $headerIntroImageList.attr('style', 'opacity: 0;');
            //$headerIntroImage.attr('style', 'opacity: 0; filter: blur(0); webkit-filter: blur(0);');
        }
        else {
            // blur
            /*blur = Math.round(offset * 100 / headerIntroImageHeight / 4);
            if (blur < 0) blur = 0;
            else if (blur > 100 / 4) blur = 100 / 4;*/

            // opacity by %
            /*opacity = Math.round(offset * 100 / headerIntroImageHeight * opacityDefault * opacityMultiplier);
            opacity = Math.round(100 - opacity - (100 - opacityPercentageDefault));
            if (opacity < 0) opacity = 0;
            else if (opacity > 100) opacity = 100;
            $headerIntroImage.attr('style', 'opacity: ' + opacity + '%;');

            $headerIntroImage.attr('style', 'opacity: ' + opacity + '%; webkit-filter: blur(' + blur + 'px); filter: blur(' + blur + 'px);');*/

            // opacity by alpha
            opacity = Math.round((offset / headerIntroImageHeight * opacityDefault * opacityMultiplier) * 100) / 100;
            opacity = Math.round((1 - opacity - (1 - opacityDefault)) * 100) / 100;
            if (opacity < 0) opacity = 0;
            else if (opacity > 1) opacity = 1;
            $headerIntroImageList.attr('style', 'opacity: ' + opacity + ';');

            //$headerIntroImage.attr('style', 'opacity: ' + opacity + '; webkit-filter: blur(' + blur + 'px); filter: blur(' + blur + 'px);');

            //$headerIntroImage.removeAttr('style');
        }

        // scrolling timer
        scrollingPage = true;
        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(function() {
            scrollingPage = false;
        }, scrollingPageTimeout));
    }).scroll();
});
